<template>
  <div class="section">
    <div class="d-flex justify-content-center mb-1 mt-3" v-if="isLoading">
      <b-spinner label="Loading..." />
    </div>
    <vue-good-table
      v-else
      max-height="80vh"
      :columns="columns"
      :rows="stocks"
      :fixed-header="false"
      :sort-options="{
        enabled: false,
      }"
      :pagination-options="{
        enabled: false,
      }"
    >
      <template slot="table-column" slot-scope="props">
        <span v-if="props.column.field == 'action'" />
      </template>
      <template slot="table-row" slot-scope="props">
        <!-- Column: Nama Produk -->
        <span v-if="props.column.field === 'product'">
          <template v-for="(item, index) in props.row.items">
            <div :key="index">
              {{ item.product ? item.product.name : "-" }}
            </div>
          </template>
        </span>

        <!-- Column: Stok awal -->
        <span v-if="props.column.field === 'stock_before_opname'">
          <template v-for="(item, index) in props.row.items">
            <div :key="index">
              {{ item.stock_before_opname }}
            </div>
          </template>
        </span>

        <!-- Column: note -->
        <span v-else-if="props.column.field === 'note'">
          <div class="text-capitalize note">
            {{ props.row.note || "-" }}
          </div>
        </span>

        <!-- Column: qty -->
        <span v-else-if="props.column.field === 'qty'">
          <template v-for="(item, index) in props.row.items">
            <div :key="index">
              {{ item.qty }}
            </div>
          </template>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown id="dropdown-dropleft" class="d-flex" right>
              <template #button-content class="btn-white text-center">
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle"
                />
              </template>
              <!-- <b-dropdown-item v-if="checkPermission('approve/reject stock opname')" @click="approval(props.row.uuid, 'approved')"> -->
              <b-dropdown-item @click="goToDetail(props.row.uuid)">
                <feather-icon icon="CheckCircleIcon" class="mr-1" />
                <span> Persetujuan </span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="checkPermission('approve/reject stock opname')"
                @click="approval(props.row.uuid, 'rejected')"
              >
                <feather-icon icon="XCircleIcon" class="mr-1" />
                <span> Reject </span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
    <div
      v-if="rows"
      class="d-flex justify-content-between align-items-center flex-wrap bg-white border"
      style="padding: 0.8rem"
    >
      <div class="d-flex align-items-center">
        <b-form-select
          v-model="filter.per_page"
          :options="['10', '15', '20']"
          class="mx-1"
        />
        <span class="text-nowrap">
          Tampilkan
          {{
            rows.total > 0 ? filter.per_page * (rows.current_page - 1) + 1 : 0
          }}
          ke
          {{
            rows.total > 0
              ? Math.min(filter.per_page * rows.current_page, rows.total)
              : 0
          }}
          dari {{ rows.total }} transaksi.
        </span>
      </div>
      <div>
        <b-row class="mx-0 align-items-center">
          <b-col>
            <pagination
              class="mb-0"
              :data="rows"
              :limit="4"
              align="right"
              @pagination-change-page="getDataApproval"
            />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BFormSelect,
  VBToggle,
  BRow,
  BCol,
  BSpinner,
} from "bootstrap-vue";
import {
  checkPermission,
  successNotification,
  errorNotification,
} from "@/auth/utils";

import { VueGoodTable } from "vue-good-table";

export default {
  components: {
    VueGoodTable,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    BRow,
    BCol,
    BSpinner,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    };
  },
  props: {
    resultApproval: {
      type: Object,
    },
    getData: {
      type: Function,
    },
    getDetail: {
      type: Function,
    },
    getDataApproval: {
      type: Function,
    },
    loading: {
      type: Boolean,
    },
  },
  data() {
    return {
      columns: [
        {
          label: "Tanggal",
          field: "date",
        },
        // {
        //   label: 'SKU Produk',
        //   field: 'product.sku_code',
        // },
        {
          label: "Nama Produk",
          field: "product",
        },
        {
          label: "Stok Awal",
          field: "stock_before_opname",
        },
        {
          label: "Stok Terbaru",
          field: "qty",
        },
        {
          label: "Catatan",
          field: "note",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      rows: {},
      stocks: [],
      filter: {
        per_page: 10,
      },
      isLoading: false,
    };
  },
  watch: {
    loading(value) {
      this.isLoading = value;
    },
    resultApproval: {
      handler(value) {
        this.rows = value;
        this.stocks = value.data;
      },
      deep: true,
    },
  },
  mounted() {
    this.rows = this.resultApproval || {};
    this.stocks = this.resultApproval?.data || [];
  },
  methods: {
    approval(id, status) {
      this.$store
        .dispatch("stockOpname/postData", {
          type: "set-status",
          payload: {
            uuid: id,
            status,
          },
        })
        .then(() => {
          this.getData();
          this.getDataApproval();
          successNotification(
            this,
            "Success",
            `Produk opname berhasil di ${status}`
          );
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    goToDetail(uuid) {
      this.$store.commit("stockOpname/setIsDetail", false);
      this.getDetail(uuid);
      this.$bvModal.show("modal-detail-stock-opname");
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.vgt-responsive {
  height: 71.5vh;
  background-color: #fff;
}
</style>
